.App {
  text-align: center;
}
.whatsapp {
  position: fixed;
  top: 84%;
  right: 4%;
  font-size: 35px;
  width: 45px;
  height: 45px;
  transition: all 0.5s ease;
  text-decoration: none;
  color: black;
  background: white;
  border: 1.5px solid gray;
  border-radius: 50%;
}

@media only screen and (max-width: 1100px) {
  
}