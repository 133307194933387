.navbarContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.5vw 0 3vw;
    height: 80px;
}
.navbarContainer p {
    font-size: 30px;
    font-weight: 500;
}
.spanContainer span {
    margin-left: 20px;
    font-size: 20px;
    font-weight: 500;
    color: black;
}
.spanContainer a {
    text-decoration: none;
}
.spanContainer span:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    cursor: pointer;
}
.navbarContainer h2 {
    display: none;
}

@media only screen and (max-width: 1100px) {
    .navbarContainer {
        height: 70px;
    }
    .navbarContainer p {
        margin-left: 20px;
        font-size: 30px;
    }
    .spanContainer {
        display: none;
    }
    .navbarContainer h2 {
        display: inline-block;
        font-weight: 500;
        font-size: 40px;
        margin-right: 20px;
    }
}