.mainInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 700px;
    background: linear-gradient(rgba(0, 0, 0, 0.408), rgba(0, 0, 0, 0.408)), url('../../../pictures/salon-general.png');
    background-position: center;
    background-size: 100%;
    background-attachment: fixed;
    background-clip:content-box;
    transition: all 0.75s
}
.mainInfo:hover {
    background-size: 110%;
    filter: brightness(150%);
}
.mainInfo p {
    color: white;
    font-size: 4rem;
}

@media only screen and (max-width: 1100px) {
    .mainInfo {
        height: 52vw;
        background-size: 110%;
        background-position:top;
        background-repeat: no-repeat;
    }
    .mainInfo p {
        font-size: 6vw;
    }
}